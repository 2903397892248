import { mapGetters } from 'vuex';

export default {
  name: 'SearchPerson',
  props: {
    qhid: {
      type: Number|String,
      default: 0
    },
    tracking: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: null
    },
    showNewSearch: {
      type: Boolean,
      default: true
    },
    resultsNotFoundMessage: {
      type: String,
      default: 'No patient found matching search criteria.'
    },
    AdditionalInfoMessage: {
      type: String,
      default: 'Could not match patient based on search criteria. Please try including the First Name, Last Name and try again.'
    },
    context: {
      type: Object,
      default: null
    },
    memberInfo: {
      type: Object,
      default: null
    },
    pageName: {
      type: String,
      default: ''
    },
    successfulMemberSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        firstname: {
          id: 'firstname',
          value: null,
          maxLength: 50,
          required: false,
          label: 'First Name'
        },
        middlename: {
          id: 'middlename',
          value: null,
          maxLength: 1,
          required: false,
          placeholder: 'Optional',
          label: 'Middle Initial'
        },
        lastname: {
          id: 'lastname',
          value: null,
          maxLength: 50,
          required: false,
          label: 'Last Name'
        },
        dob: {
          id: 'dob',
          value: null,
          required: true,
          date: {
            minDate: new Date(1900, 0, 1),
            maxDate: new Date(),
          },
          label: 'Date of Birth'
        },
        memberid: {
          id: 'memberid',
          value: null,
          maxLength: 100,
          required: true,
          label: 'Member ID from ID Card'
        }
      },
      internalQhid: 0,
      showNoMatchInternal: false,
      showAdditionalDataInternal: false,
      loading: false,
      showMemberSearch: true,
      showSearchResult: false,
      formContext: {}
    }
  },
  computed: {
    formKeys() {
      return Object.getOwnPropertyNames(this.form).filter(x => x !== '__ob__');
    },
    ...mapGetters('currentUser', [
      'temporaryMemberInfo'
    ])
  },
  methods: {
    onReset() {},
    onSubmit() {},
    beforeMount() {},
    afterAutoLoad() {},
    reset(clearFields) {
      this.internalQhid = 0;
      this.showNoMatchInternal = false;
      this.showAdditionalDataInternal = false;

      if (clearFields) {
        this.formContext.reset();
      }

      this.$emit('reset');
      this.showMemberSearch = true;
      this.showSearchResult = false;
      this.$emit('update:memberInfo', this.buildMemberInfo());

      this.onReset();
    },
    setQhid(response) {
      this.internalQhid = response.qhid;
      this.showAdditionalDataInternal = (response === "");
      this.showNoMatchInternal = (response.qhid === 0);
      if(response.qhid>0){
        this.setFieldValues(response);
      }
      this.$store.commit('currentUser/setQhid', response.qhid)
    },
    submit(request) {
      this.loading = true;
      this.onSubmit(request).then(() => {
        this.loading = false;
        this.showMemberSearch = false;
        this.showSearchResult = true;
        this.$emit('update:memberInfo', this.buildMemberInfo());
      }).catch(() => {
        this.$toasted.global.qh_toast_error();
        this.loading = false;
      });
    },
    setFieldValues(newValues) {
      // Iterate over the form keys and set corresponding values
      this.formKeys.forEach(key => {
        if(key=="firstname"){
          this.form[key].value  = newValues.firstName
        }
        else if(key=="middlename"){
          this.form[key].value  = newValues.middleInitial
        }
        else if(key=="lastname"){
          this.form[key].value  = newValues.lastName
        }
      });
    },

    buildMemberInfo() {
      const info = {};
      
      for (let i = 0; i < this.formKeys.length; i++) {
        const formKey = this.formKeys[i];        
          info[formKey] = this.form[formKey].value;    
      }

      return info;
    }
  },
  watch: {
    qhid(to) {
      if (!to || to === 0) {
        this.reset();
      }
    },
    formContext(to) {
      this.$emit('update:context', to);
    },
    internalQhid() {
      this.$emit('update:qhid', this.internalQhid);
    },
    showSearchResult() {
      this.$emit('update:showSearchResult', this.showSearchResult);
    },
    showNoMatchInternal() {
      this.$emit('update:showNoMatch', this.showNoMatchInternal);
    },
    showAdditionalDataInternal(){
      this.$emit('update:showAdditionalData', this.showAdditionalDataInternal);
    }
  },
  mounted() {
    this.beforeMount();
    
    if (this.temporaryMemberInfo) {
      for (let i = 0; i < this.formKeys.length; i++) {
        const formKey = this.formKeys[i];
        if (this.temporaryMemberInfo[formKey]) {
          this.form[formKey].value = this.temporaryMemberInfo[formKey];
        }
      }

      this.$nextTick(() => {
        this.showMemberSearch = false;
        this.showSearchResult = true;
        this.setQhid(this.temporaryMemberInfo.qhid ?? 0);
        this.$emit('update:memberInfo', this.buildMemberInfo());
        this.$store.dispatch("currentUser/setTemporaryMemberInfo", null);

        this.afterAutoLoad();
      });
    }
  }
}