import steps from '@/services/forgotPasswordSteps.js';
import accountService from '@/services/api/account.js';

const flowName = 'ForgotPassword';

const state = {
  step: null,
  data: null,
  locked: null,
  preMfa: null,
  accountLocked: null,
  mfaMethod: null,
  complete: false
};

const getters = {
  step(state) {
    return state.step;
  },
  data(state) {
    // return copy of object
    return { ...state.data }
  },
  locked(state) {
    return state.locked;
  },
  preMfa(state) {
    return state.preMfa;
  },
  mfaMethod(state) {
    return state.mfaMethod;
  },
  accountLocked(state) {
    return state.accountLocked;
  },
  complete(state) {
    return state.complete;
  }
};

const actions = {
  initialize({ commit }) {
    return new Promise(resolve => {
      commit('initialize');
      resolve();
    });
  },
  begin({ commit, getters, rootGetters }, { firstName, lastName, email }) {
    return new Promise((resolve, reject) => {
      accountService.getForgotPasswordInfo({
        firstName,
        lastName,
        email
      }).then(response => {
        if (response.error) {
          handleUserError({ commit, reject, getters, response });
          return;
        }

        commit('setMfaMethod', null);
        commit('setPreMfa', response.isPreMfa);

        commit('setData', {
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          helperId: response.helperId,
          mobile: response.mobile,
          securityQuestion: response.securityQuestion,
          securityAnswerFormat: response.securityAnswerFormat,
          password: null,
          securityQuestion1: null,
          securityQuestion2: null,
          securityQuestion3: null,
          securityAnswer1: null,
          securityAnswer2: null,
          securityAnswer3: null,
          termsAccepted: null
        });

        if (rootGetters['settings/registrationUsesSms'] && !getters.preMfa && getters.data.mobile) {
          commit('setStep', steps.mfaMethod);
          resolve(steps.mfaMethod);
          return;
        }

        accountService.sendMfaEmail(
          getters.data.email,
          getters.data.email, 
          flowName
        ).then(() => {
          commit('setStep', steps.mfa);
          commit('setMfaMethod', 'email');
          resolve(steps.mfa);
          }).catch(() => {
          reject();
          });
      }).catch(() => {
        reject();
      });
    });
  },
  chooseMfaMethod({ commit, getters }, mfaMethod) {
    return new Promise((resolve, reject) => {
      commit('setMfaMethod', mfaMethod);
      
      const action = mfaMethod === 'mobile'
        ? () => accountService.sendMfaText(getters.data.email, getters.data.mobile, flowName)
        : () => accountService.sendMfaEmail(getters.data.email, getters.data.email, flowName);

      action().then(() => {
        commit('setStep', steps.mfa);
        resolve(steps.mfa);
      }).catch(() => {
        reject();
      });
    });
  },
  submitMfa({ commit, getters }, code) {
    return new Promise((resolve, reject) => {
      accountService.verifyMfa(getters.data.email, code, flowName).then(response => {
        if (response.error) {
          handleUserError({ commit, reject, getters, response });
          return;
        }

        commit('setStep', steps.setPassword);
        resolve(steps.setPassword);
      }).catch(() => {
        reject();
      });
    });
  },
  setPassword({ commit, getters }, password) {
    return new Promise((resolve, reject) => {
      try {
        commit('setPassword', password);

        const nextStep = getters.preMfa
          ? steps.addMobileNumber
          : steps.finalize;

        commit('setStep', nextStep);
        resolve(nextStep);
      } catch {
        reject();
      }
    });
  },
  addMobileNumber({ commit }, { mobile, skipMobile }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setMobile', { mobile, skipMobile });
        commit('setStep', steps.setSecurityQuestions);
        resolve(steps.setSecurityQuestions);
      } catch {
        reject();
      }
    });
  },
  setSecurityQuestions({ commit }, {
    securityQuestion1,
    securityQuestion2,
    securityQuestion3,
    securityAnswer1,
    securityAnswer2,
    securityAnswer3
  }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setSecurityQuestions', {
          securityQuestion1,
          securityQuestion2,
          securityQuestion3,
          securityAnswer1,
          securityAnswer2,
          securityAnswer3
        });

        commit('setStep', steps.termsOfService);
        resolve(steps.termsOfService);
      } catch {
        reject();
      }
    });
  },
  setTermsAccepted({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setTermsAccepted', true);
        commit('setStep', steps.finalize);

        resolve(steps.finalize);
      } catch {
        reject();
      }
    });
  },
  finalize({ commit, getters }) {
    commit('setComplete', true);
    return new Promise((resolve, reject) => {
      const data = getters.data;
      accountService.updateAccountFromForgotPassword({
        accountId: data.helperId,
        email: data.email,
        mobile: data.mobile,
        password: data.password,
        securityQuestion1: data.securityQuestion1,
        securityQuestion2: data.securityQuestion2,
        securityQuestion3: data.securityQuestion3,
        securityAnswer1: data.securityAnswer1,
        securityAnswer2: data.securityAnswer2,
        securityAnswer3: data.securityAnswer3,
        termsAccepted: data.termsAccepted
      }).then(() => {
        resolve();
      }).catch(() => {
        commit('setComplete', false);
        reject();
      });
    });
  }
};

const mutations = {
  initialize(state) {
    state.locked = false;
    state.accountLocked = false;
    state.preMfa = false;
    state.mfaMethod = null;
    state.step = steps.begin;
    state.complete = false;

    state.data = {
      firstName: null,
      lastName: null,
      email: null,
      mobile: null,
      skipMobile: null,
      helperId: null,
      securityQuestion: null,
      securityQuestion1: null,
      securityQuestion2: null,
      securityQuestion3: null,
      securityAnswer1: null,
      securityAnswer2: null,
      securityAnswer3: null,
      termsAccepted: null
    };
  },
  setStep(state, step) {
    state.step = step;
  },
  setData(state, data) {
    state.data = data;        
  },
  setLocked(state, locked) {
    state.locked = locked;
  },
  setPreMfa(state, preMfa) {
    state.preMfa = preMfa;
  },
  setMfaMethod(state, method) {
    state.mfaMethod = method;
  },
  setAccountLocked(state, locked) {
    state.accountLocked = locked;
  },
  setPassword(state, password) {
    state.data.password = password;
  },
  setMobile(state, { mobile, skipMobile }) {
    state.data.skipMobile = skipMobile;
    state.data.mobile = !skipMobile ? mobile : null;
  },
  setSecurityQuestions(state, {
    securityQuestion1,
    securityQuestion2,
    securityQuestion3,
    securityAnswer1,
    securityAnswer2,
    securityAnswer3
  }) {
    state.data.securityQuestion1 = securityQuestion1;
    state.data.securityQuestion2 = securityQuestion2;
    state.data.securityQuestion3 = securityQuestion3;

    state.data.securityAnswer1 = securityAnswer1;
    state.data.securityAnswer2 = securityAnswer2;
    state.data.securityAnswer3 = securityAnswer3;
  },
  setTermsAccepted(state, termsAccepted) {
    state.data.termsAccepted = termsAccepted;
  },
  setComplete(state, complete) {
    state.complete = complete;
  }
};

function handleUserError({ commit, getters, reject, response }) {
  if (response.attemptInfo && getters.locked !== response.attemptInfo.locked) {
    commit('setLocked', response.attemptInfo.locked);
  }

  if (response.isLockedAccount && getters.accountLocked !== response.isLockedAccount) {
    commit('setAccountLocked', response.isLockedAccount);
  }

  reject(response);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}